import React, { useState, useCallback, Fragment } from 'react'
import Box from '@mui/joy/Box'
import Sheet from '@mui/joy/Sheet'
import Stack from '@mui/joy/Stack'
import ChatBubble from './ChatBubble'
import MessageInput from './MessageInput'
import MessagesPaneHeader from './MessagesPaneHeader'
import { post } from 'aws-amplify/api'

const sessionId = Math.random().toString()

export default function MessagesPane() {
  const [chatMessages, setChatMessages] = useState([{
    sender: 'Tranebot',
    content: 'Hello! How can I help you? Right now I know things about Trane Air Handlers.',
  }])
  const [textAreaValue, setTextAreaValue] = useState('')

  const sendMessage = useCallback(async () => {
    // Save the message immediately
    const userMessage = textAreaValue;
    setTextAreaValue(''); // Clear input right away

    setChatMessages(m => [
      ...m,
      {
        sender: 'You',
        content: userMessage,
      },
      {
        sender: 'Tranebot',
        content: '',
        complete: false,
        searching: true
      },
    ])

    try {
      const restOperation = await post({
        apiName: 'technicianbotapi',
        path: '/chat',
        options: {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sessionId,
            message: userMessage,
          }),
        }
      });

  
      const { body } = await restOperation.response;
      const response = await body.json();
      
      setChatMessages(messages => {
        const newMessages = [...messages];
        const lastMessage = newMessages[newMessages.length - 1];
        lastMessage.content = response.completion;
        lastMessage.complete = true;
        lastMessage.searching = false;
        return newMessages;
      });

    } catch (error) {
      console.error('Error:', error);
      setChatMessages(messages => [
        ...messages.slice(0, -1),
        {
          sender: 'Tranebot',
          content: '[error: ' + error.message + ']',
          complete: true
        },
      ]);
    }
  }, [textAreaValue])

  return (
    <Sheet
      sx={{
        height: { xs: '100dvh' },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
      }}
    >
      <MessagesPaneHeader />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: 'scroll',
          flexDirection: 'column-reverse',
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {chatMessages.map((message, messageIndex) => {
            const isYou = message.sender === 'You';
            return (
              <Fragment key={messageIndex}>
                <Stack
                  direction="row"
                  spacing={2}
                  flexDirection={isYou ? 'row-reverse' : 'row'}
                >
                  <ChatBubble 
                    variant={isYou ? 'sent' : message.complete ? 'received' : 'incomplete'} 
                    {...message} 
                  />
                </Stack>
                {message.context?.map((ctx, ctxIndex) => (
                  <Stack
                    key={`${messageIndex}-context-${ctxIndex}`}
                    direction="row"
                    spacing={2}
                    flexDirection={isYou ? 'row-reverse' : 'row'}
                  >
                    <ChatBubble 
                      variant={'received'} 
                      attachment={{ fileName: ctx.name, size: ctx.pageNumber }} 
                    />
                  </Stack>
                ))}
              </Fragment>
            );
          })}
        </Stack>
      </Box>

      <MessageInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        onSubmit={sendMessage}
      />
    </Sheet>
  )
}