import React, { useState, useEffect } from 'react'
import Select from '@mui/joy/Select'
import Option from '@mui/joy/Option'
import Stack from '@mui/joy/Stack'
import Button from '@mui/joy/Button'
import Link from '@mui/joy/Link'
import {
  fetchAuthSession,
  signOut,
} from 'aws-amplify/auth'
import tranebotImage from '../images/tranebot.png';


const eModel = !window.location.hash ? '' : window.location.hash.split(',')[0].replace('#', '')
const lModel = !window.location.hash ? '' : window.location.hash.split(',')[1]

export default function MessagesPaneHeader () {
  const [session, setSession] = useState()
  useEffect(() => {
    void (async () => {
      setSession(await fetchAuthSession())
    })()
  }, [])
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.body',
      }}
      py={{ xs: 2, md: 2 }}
      px={{ xs: 1, md: 2 }}
    >
      <img src={tranebotImage} width="200" />
      <Stack spacing={1} direction="row" alignItems="center">
        <div>{session?.tokens?.idToken?.payload?.email ?? ''} (<Link onClick={() => signOut()}>sign out</Link>)</div>
      </Stack>
    </Stack>
  )
}
