import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { StyledEngineProvider, CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import { Amplify } from "aws-amplify";
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import Button from '@mui/joy/Button';
import { signInWithRedirect, fetchAuthSession } from 'aws-amplify/auth';

import MessagesPane from './components/MessagesPane';
const SUPPORTED_MODELS = [ 'TAMX']
import '@fontsource/inter';

function App() {
  const [session, setSession] = useState(null);
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    void (async () => {
      const s = await fetchAuthSession();
      console.log('session', s);
      if (!s.tokens) {
        await signInWithRedirect({ provider: { custom: 'trane-residential-cognito' } });
      } else {
        setSession(s);
      }
    })();
  }, []);

  return !session ? null : <>
    <MessagesPane />
    {showIntro && <Card
      sx={{
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        height: '650px',
        overflow: 'auto',
      }}
    >
      <Typography level="title-lg">
        Welcome to the Technician Bot Test Console
      </Typography>
      <Divider inset="none" />
      <CardContent>
        <Typography>
          This is an <strong>extremely</strong> early preview of the "Guided
          Service" technician bot. Currently, this bot has access to the product
          literature from most air handlers that are included in the product
          literature database.
        </Typography>
        <ul>
          {SUPPORTED_MODELS.map((handler, index) => (
            <li key={index}>{handler}</li>
          ))}
        </ul>
        <Typography>
          More equipment will be added over the coming weeks as we start to work
          out the kinks.
        </Typography>
        <Typography>
          Additionally, there is a long list of things that do not work yet, but
          are coming soon, including:
        </Typography>
        <ul>
          <li>Returning the manual pages where the information was sourced from.</li>
          <li>Returning an image or diagram where it would be more helpful than text.</li>
        </ul>
      </CardContent>
      <CardActions>
        <Button onClick={() => setShowIntro(false)}>Okay</Button>
      </CardActions>
    </Card>}
  </>
}

const getConfigUrl = () => {
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  if (isLocalhost) {
    return '/amplify-config-trane.json';
  }
  return `https://metadata.${window.location.host.split('.').slice(-2).join('.')}/amplify-config-trane.json`;
};

const getApiUrl = () => {
  const isLocalhost = window.location.hostname === 'localhost'
  if (isLocalhost) {
    return 'https://technician-bot-api.tr-dev.net'
  }
  return `https://technician-bot-api.${window.location.host.split('.').slice(-2).join('.')}`
}

fetch(getConfigUrl())
  .then((response) => response.json())
  .then((config) => {
    const updatedConfig = {
      ...config,
      API: {
        ...config.API,
        REST: {
          technicianbotapi: {
            endpoint: getApiUrl(),
            region: config.API?.REST?.technicianBotAPI?.region || 'us-east-1',
            service: 'execute-api',
          }
        }
      }
    };
    
    Amplify.configure(updatedConfig);
    console.log('Amplify Configuration:', JSON.stringify(updatedConfig, null, 2));

    const rootElement = document.getElementById('root');
    const root = createRoot(rootElement);
    
    root.render(
      <StyledEngineProvider injectFirst>
        <CssVarsProvider disableTransitionOnChange>
          <CssBaseline />
          <App />
        </CssVarsProvider>
      </StyledEngineProvider>
    );
  });